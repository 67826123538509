
import './App.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import UserDashBoard from './components/UserDashBoard/UserDashBoard';
import { useEffect, useState } from 'react';
import AdminDashBoard from './components/AdminDashBoard/AdminDashBoard';

function App() {



  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [user_id, setUID] = useState(0);


  const handleLogout = () => {
    window.open('/','_self');
    setToken("");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem('user_type');
    window.localStorage.removeItem('uid');
  };


  const handleLogin = (newToken, newuid, user_type) => {
    window.open('/','_self')
    setToken(newToken);
    window.localStorage.setItem("user_type", user_type);
    window.localStorage.setItem("uid", newuid);

    // console.log("User type ye hai: ", user_type);
    // console.log("UID ye hai: ", newuid);
  };

  useEffect(() => {
    const storedToken = window.localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      const storedRole = window.localStorage.getItem("user_type");
      const uid = window.localStorage.getItem('uid');
      if (storedRole) {
        setRole(storedRole);
        setUID(uid);
      }
      // if(fac_id) { setFaculty_ID(fac_id)}
    }

    // console.log("Role ye hai:", role);
    // console.log("User_ID : ", user_id);
  }, [token, role]);


  return (
    <>
      <Router>

        {role == 1 ? (
          <AdminDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} role={role} />

        ) : (
          <UserDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} />

        )}
      </Router>
      {/* <CategoryData />
      <ProductList />
      <Product />
      <Register /> */}
    </>
  );
}

export default App;
